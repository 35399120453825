import React from "react"
import Layout from "../components/layout"
import Container from "../components/container"
import SEO from "../components/seo"
import styled from "@emotion/styled"

const ImprintPage = () => {
  return (
    <Layout>
      <SEO title="Impressum" />
      <StyledImprint>
        <h1>Impressum</h1>
        <h2>Anschrift/Kontakt</h2>
        <address>
          raum3 GmbH
          <br />
          Karl-Schurz-Straße 8a
          <br />
          66119 Saarbrücken
          <br />
          Deutschland
        </address>

        <p>
          Tel: 0681 / 9 54 37-10
          <br />
          Fax: 0681 / 9 54 37-12
          <br />
          eMail: info@raum3.de
        </p>

        <p>
          Amtsgericht Saarbrücken
          <br />
          Handelsregister-Nummer HRB 16750
          <br />
          USt-Ident-Nr. DE 257 118 470
        </p>

        <h2>Inhaltlich verantwortlich nach § 55 Abs.2 RStV:</h2>
        <p>Sascha Metz (Anschrift siehe oben)</p>

        <h2>Geschäftsführer:</h2>
        <p>Sascha Metz</p>

        <h2>Haftungshinweis:</h2>
        <p>
          Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
          für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten
          sind ausschließlich deren Betreiber verantwortlich.
        </p>

        <h2>Datenschutz:</h2>
        <p>
          Die auf unserer Seite veröffentlichten Beiträge, Texte und Abbildungen
          sind urheberrechtlich geschützt. Jede vom Urhebergesetz nicht
          ausdrücklich zugelassene Verwertungbedarf unserer vorherigen
          schriftlichen Zustimmung.
        </p>

        <h2>Marke:</h2>
        <p>
          &bdquo;raum3&ldquo; ist eine eingetragene Marke (30756302.2) beim
          Deutschen Patent- und Markenamt in München. Bei Missachtungen und
          Verletzungen der Marke sehen wir uns gezwungen, rechtliche Schritte
          einzuleiten.
        </p>
      </StyledImprint>
    </Layout>
  )
}

const StyledImprint = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;
  h2 {
    font-size: var(--fontSizeH4);
  }
`

export default ImprintPage
